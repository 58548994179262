import React from 'react';
import {Container,Row,Col} from '@bootstrap-styled/v4';
import {FooterWrapper,FooterCol,FooterTitle,FooterPara,FooterLogo,
    FooterUl,FooterLi,FooterMenu,FooterSocial,BottomFooterPara,BottomLink,
    InstagramIcon,YoutubeIcon,TwitterIcon,FacebookIcon,BottomFooterWrapper
} from './footer.style';

import ScrollSpy from 'react-scrollspy';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from "react-reveal/Fade";
    
    
const Footer = () =>{
    const JSONData  = useStaticQuery(graphql`
        query{
            app2Json {
                Footer {
                    DelayConstant
                    FooterCol1{
                        FooterLogo
                        FooterPara
                    }
                    FooterCol2{
                        FooterTitle
                        FooterPara
                    }
                    FooterCol3{
                        FooterTitle
                        FooterUl{
                            FooterMenu
                        }
                    }
                    FooterCol4{
                        FooterTitle
                        FooterUl{
                            FooterMenu
                            Href
                        }
                    }
                    BottomFooterPara
                    BottomFooterName
                    BottomFooterLink
                }
            }
        }
    `);
        return (
        <div>
        <FooterWrapper>
            <Container>
                <Fade bottom delay={1*JSONData.app2Json.Footer.DelayConstant}>
                    <Row>
                        <Col md="3" sm="6">
                            <FooterCol>
                                <FooterLogo src={JSONData.app2Json.Footer.FooterCol1.FooterLogo} alt=""/>
                                <FooterPara>
                                {JSONData.app2Json.Footer.FooterCol1.FooterPara}
                                </FooterPara>
                                <FooterSocial href="#" aria-label="Instagram Link">
                                    <InstagramIcon/>
                                </FooterSocial>

                                <FooterSocial href="#" aria-label="Youtube Link">
                                    <YoutubeIcon/>
                                </FooterSocial>

                                <FooterSocial href="#" aria-label="Twitter Link">
                                    <TwitterIcon/>
                                </FooterSocial>

                                <FooterSocial href="#" aria-label="Facebook Link">
                                    <FacebookIcon/>
                                </FooterSocial>
                            </FooterCol>
                        </Col>
                        <Col md="3" sm="6">
                            <FooterCol>
                                <FooterTitle>{JSONData.app2Json.Footer.FooterCol2.FooterTitle}</FooterTitle>
                                <FooterPara>
                                    {JSONData.app2Json.Footer.FooterCol2.FooterPara}
                                </FooterPara>
                            </FooterCol>
                        </Col>
                        <Col md="3" sm="6">
                            <FooterCol>
                            <FooterTitle>{JSONData.app2Json.Footer.FooterCol3.FooterTitle}</FooterTitle>
                                <FooterUl>
                                {
                                    JSONData.app2Json.Footer.FooterCol3.FooterUl.map((item,idx) => {
                                    return <FooterLi>
                                        <FooterMenu href="#">
                                            {item.FooterMenu}
                                        </FooterMenu>
                                    </FooterLi>
                                    })
                                }
                                </FooterUl>
                            </FooterCol>
                        </Col>
                        <Col md="3" sm="6">
                            <FooterCol>
                            <FooterTitle>{JSONData.app2Json.Footer.FooterCol4.FooterTitle}</FooterTitle>
                                <ScrollSpy offset={-59} items={JSONData.app2Json.Footer.Items} currentClassName="is-current">
                                {
                                    JSONData.app2Json.Footer.FooterCol4.FooterUl.map((item,idx) => {
                                    return <FooterLi>
                                        <AnchorLink offset={55} href={item.Href}>
                                            {item.FooterMenu}
                                        </AnchorLink>
                                    </FooterLi>
                                    })
                                }
                                </ScrollSpy>
                            </FooterCol>
                        </Col>
                    </Row>
                </Fade>
            </Container>
        </FooterWrapper>

        <BottomFooterWrapper>
            <Container>
                <Fade bottom delay={2*JSONData.app2Json.Footer.DelayConstant}>
                    <Row>
                        <Col md="12">
                            <BottomFooterPara>
                                {JSONData.app2Json.Footer.BottomFooterPara}
                                <BottomLink rel="noreferrer" href={JSONData.app2Json.Footer.BottomFooterLink} target="_blank">
                                {JSONData.app2Json.Footer.BottomFooterName}
                                </BottomLink>
                            </BottomFooterPara>
                        </Col>
                    </Row>
                </Fade>
            </Container>
                    
        </BottomFooterWrapper>

        
    </div>
    );
}

export default Footer;