import React from 'react';
import {FeaturesWrapper,FeaturesRight,FeaturesCard,
FeaturesCardImg,FeaturesCardIcon,FeaturesCardHeading,FeaturesCardPara,RightArrowYellow,RightArrow,
FeaturesCardCenter,FeaturesRow,FeaturesBtn,ButtonWrapper
} from './features.style';
import {Container, Row, Col} from '@bootstrap-styled/v4';

import { useStaticQuery, graphql } from 'gatsby';
import Fade from "react-reveal/Fade";

const Features = () => {
    const JSONData  = useStaticQuery(graphql`
        query{
            app2Json {
                Features {
                    DelayConstant
                    FeaturesRight{
                        FeaturesCardIcon
                        FeaturesCardHeading
                        FeaturesCardPara
                        FeaturesBtn
                        RightArrow
                        RightArrowYellow
                    }
                }
            }
        }
    `);
    return (
        <FeaturesWrapper id="featuresContainer">
            <Container>
                <FeaturesRow>
                    <Col md="12" lg="12">
                        <FeaturesRight>
                            <Row>
                                <Col lg="4" md="4" sm="12">
                                    <Fade bottom delay={(0+1)*JSONData.app2Json.Features.DelayConstant}>
                                        <FeaturesCard>
                                            <FeaturesCardImg>
                                                <FeaturesCardIcon src={JSONData.app2Json.Features.FeaturesRight[0].FeaturesCardIcon} alt=""/>
                                            </FeaturesCardImg>
                                            <FeaturesCardHeading>{JSONData.app2Json.Features.FeaturesRight[0].FeaturesCardHeading}</FeaturesCardHeading>
                                            <FeaturesCardPara>{JSONData.app2Json.Features.FeaturesRight[0].FeaturesCardPara}</FeaturesCardPara>
                                            <ButtonWrapper>
                                                <FeaturesBtn href="#">{JSONData.app2Json.Features.FeaturesRight[0].FeaturesBtn}
                                                    <RightArrow src={JSONData.app2Json.Features.FeaturesRight[0].RightArrow} alt=""/>
                                                    <RightArrowYellow src={JSONData.app2Json.Features.FeaturesRight[0].RightArrowYellow} alt=""/>
                                                </FeaturesBtn>
                                            </ButtonWrapper>
                                        </FeaturesCard>
                                    </Fade>
                                </Col>
                                <Col lg="4" md="4" sm="12">
                                    <Fade bottom delay={(1+1)*JSONData.app2Json.Features.DelayConstant}>
                                        <FeaturesCardCenter>
                                            <FeaturesCard>
                                                <FeaturesCardImg>
                                                    <FeaturesCardIcon src={JSONData.app2Json.Features.FeaturesRight[1].FeaturesCardIcon} alt=""/>
                                                </FeaturesCardImg>
                                                <FeaturesCardHeading>{JSONData.app2Json.Features.FeaturesRight[1].FeaturesCardHeading}</FeaturesCardHeading>
                                                <FeaturesCardPara>{JSONData.app2Json.Features.FeaturesRight[1].FeaturesCardPara}</FeaturesCardPara>
                                                <ButtonWrapper>
                                                    <FeaturesBtn href="#">{JSONData.app2Json.Features.FeaturesRight[1].FeaturesBtn}
                                                        <RightArrow src={JSONData.app2Json.Features.FeaturesRight[1].RightArrow} alt=""/>
                                                        <RightArrowYellow src={JSONData.app2Json.Features.FeaturesRight[1].RightArrowYellow} alt=""/>
                                                    </FeaturesBtn>
                                                </ButtonWrapper>
                                            </FeaturesCard>
                                        </FeaturesCardCenter>
                                    </Fade>
                                </Col>
                                <Col lg="4" md="4" sm="12">
                                    <Fade bottom delay={(2+1)*JSONData.app2Json.Features.DelayConstant}>
                                        <FeaturesCard>
                                            <FeaturesCardImg>
                                                <FeaturesCardIcon src={JSONData.app2Json.Features.FeaturesRight[2].FeaturesCardIcon} alt=""/>
                                            </FeaturesCardImg>
                                            <FeaturesCardHeading>{JSONData.app2Json.Features.FeaturesRight[2].FeaturesCardHeading}</FeaturesCardHeading>
                                            <FeaturesCardPara>{JSONData.app2Json.Features.FeaturesRight[2].FeaturesCardPara}</FeaturesCardPara>
                                            <ButtonWrapper>
                                                <FeaturesBtn href="#">{JSONData.app2Json.Features.FeaturesRight[2].FeaturesBtn}
                                                    <RightArrow src={JSONData.app2Json.Features.FeaturesRight[2].RightArrow} alt=""/>
                                                    <RightArrowYellow src={JSONData.app2Json.Features.FeaturesRight[2].RightArrowYellow} alt=""/>
                                                </FeaturesBtn>
                                            </ButtonWrapper>
                                        </FeaturesCard>
                                    </Fade>
                                </Col>
                            </Row>
                        </FeaturesRight>
                    </Col>
                </FeaturesRow>
            </Container>
        </FeaturesWrapper>

    );
}

export default Features;