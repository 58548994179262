import React from 'react';
import {AppFeaturesSectionWrapper,AppFeaturesHeadingWrapper,
    AppFeaturesHeading,AppFeaturesSubHeading,AppFeaturesInner,AppFeaturesWrapper,AppFeaturesText,
    AppFeaturesBold,AppFeaturesLeftIcon,AppFeaturesImg,AppFeaturesCenter,CenterCol,AppFeaturesTextNormal
} from './appfeatures.style';
import {Container, Row, Col} from '@bootstrap-styled/v4';
import GatsImg from "gatsby-image";
import { useStaticQuery, graphql } from 'gatsby';
import Fade from "react-reveal/Fade";

const AppFeatures = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                app2Json{
                    AppFeatures{
                        AppFeaturesHeading
                        AppFeaturesSubHeading
                        AppFeaturesInner1{
                            AppFeaturesLeftBold
                            text
                            AppFeaturesImg
                        }
                        AppFeaturesInner2{
                            AppFeaturesMobile{
                                childImageSharp{
                                    fluid(quality: 100){
                                    ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                        AppFeaturesInner3{
                            AppFeaturesLeftBold
                            text
                            AppFeaturesImg
                        }
                    }
                }
            }
    `);
        return (
        <AppFeaturesSectionWrapper>
            <Container>
                <Row>
                    <Col md="12">
                        <Fade bottom delay={1*JSONData.app2Json.AppFeatures.DelayConstant}>
                            <AppFeaturesHeadingWrapper>
                                <AppFeaturesHeading>{JSONData.app2Json.AppFeatures.AppFeaturesHeading}</AppFeaturesHeading>
                                <AppFeaturesSubHeading>{JSONData.app2Json.AppFeatures.AppFeaturesSubHeading}</AppFeaturesSubHeading>
                            </AppFeaturesHeadingWrapper>
                        </Fade>
                    </Col>
                </Row>

                <AppFeaturesInner>
                    <Col lg="4" md="6">
                    <Fade left delay={(2)*JSONData.app2Json.AppFeatures.DelayConstant}>
                    {
                        JSONData.app2Json.AppFeatures.AppFeaturesInner1.map((item,idx) => {
                        return <AppFeaturesWrapper bottomMargin = {idx === 2 ? "0px":"30px"}>
                            <AppFeaturesLeftIcon>
                                    <AppFeaturesImg src={item.AppFeaturesImg} alt=""/>
                            </AppFeaturesLeftIcon>
                            <AppFeaturesText  paddingLeft={"0px"}>
                                <AppFeaturesBold>{item.AppFeaturesLeftBold}</AppFeaturesBold> 
                                <AppFeaturesTextNormal>{item.text}</AppFeaturesTextNormal>
                            </AppFeaturesText>
                        </AppFeaturesWrapper>
                        })
                    }
                    </Fade>
                    </Col>

                    <CenterCol lg="4">
                        <AppFeaturesCenter>
                            <GatsImg 
                                fluid={JSONData.app2Json.AppFeatures.AppFeaturesInner2.AppFeaturesMobile.childImageSharp.fluid} 
                                className="AppFeaturesMobile"
                                imgStyle={{
                                    marginBottom:"0px",
                                    width:"100%"
                                }}
                                alt=""
                            />
                        </AppFeaturesCenter>
                    </CenterCol>
                    
                    <Col lg="4" md="6">
                    <Fade right delay={(2)*JSONData.app2Json.AppFeatures.DelayConstant}>
                    {
                        JSONData.app2Json.AppFeatures.AppFeaturesInner3.map((item,idx) => {
                        return <AppFeaturesWrapper bottomMargin = {idx === 2 ? "0px":"30px"}>
                            <AppFeaturesLeftIcon>
                                    <AppFeaturesImg src={item.AppFeaturesImg} alt=""/>
                            </AppFeaturesLeftIcon>
                            <AppFeaturesText paddingLeft={"10px"}>
                                    <AppFeaturesBold>{item.AppFeaturesLeftBold}</AppFeaturesBold> 
                                    <AppFeaturesTextNormal>{item.text}</AppFeaturesTextNormal>
                            </AppFeaturesText>
                            </AppFeaturesWrapper>
                        })
                    }
                    </Fade>
                    </Col>
                </AppFeaturesInner>
            </Container>
        </AppFeaturesSectionWrapper>
    );
}

export default AppFeatures;