import React from 'react';
import {ServicesWrapper,ServicesHeading,ServicesInner,ServicesRightLi,ButtonWrapper,
    ServicesRightLih3,ServicesRightLipara,ServicesPara,ServicesHeadingWrapper,RightArrowYellow,RightArrow,
    ServicesRightBtn,ServicesRightUl,ServicesRight,ServicesLeft,ServicesLiImgCol,CheckIcon,
    ServicesLiContentCol} from './services.style';
import {Container, Row, Col} from '@bootstrap-styled/v4';
import Fade from 'react-reveal/Fade';
import GatsImg from "gatsby-image";
import { useStaticQuery, graphql } from 'gatsby';

const Services = () => {
    const JSONData  = useStaticQuery(graphql`
        query{
            app2Json {
                Services {
                    DelayConstant
                    ServicesHeading
                    ServicesPara
                    ServicesLeftImg{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    ServicesRightUl{
                        ServicesRightLih3
                        ServicesRightLipara
                    }
                    ServicesRightBtn
                    RightArrow
                    RightArrowYellow
                }
            }
        }
    `);
        return (

        <ServicesWrapper id="servicesContainer">
            <Container>
                <Row>
                    <Col md="12">
                        <ServicesHeadingWrapper>
                            <Fade bottom delay={1*JSONData.app2Json.Services.DelayConstant}>
                            <ServicesHeading>{JSONData.app2Json.Services.ServicesHeading}</ServicesHeading>
                            </Fade>
                            <Fade bottom delay={2*JSONData.app2Json.Services.DelayConstant}>
                            <ServicesPara>{JSONData.app2Json.Services.ServicesPara}</ServicesPara>
                            </Fade>
                        </ServicesHeadingWrapper>
                    </Col>
                </Row>
                <ServicesInner>
                    <Row>
                        <Col lg="6" md="12">
                        <Fade left delay={1*JSONData.app2Json.Services.DelayConstant}>
                            <ServicesLeft>
                                <GatsImg 
                                    fluid={JSONData.app2Json.Services.ServicesLeftImg.childImageSharp.fluid} 
                                    className="ServicesLeftImg"
                                    imgStyle={{
                                        marginBottom:"0px"
                                    }}
                                    alt=""
                                />
                            </ServicesLeft>
                        </Fade>
                        </Col>
                        <Col lg="6" md="12">
                            <ServicesRight>
                                <Fade right delay={(1)*JSONData.app2Json.Services.DelayConstant}>
                                    <ServicesRightUl>
                                    { 
                                        JSONData.app2Json.Services.ServicesRightUl.map((item,idx) => {
                                        return <ServicesRightLi>
                                            <ServicesLiImgCol>
                                                <CheckIcon/>
                                            </ServicesLiImgCol>
                                            <ServicesLiContentCol>
                                                <ServicesRightLih3>{item.ServicesRightLih3}</ServicesRightLih3>
                                                <ServicesRightLipara>{item.ServicesRightLipara}</ServicesRightLipara>
                                            </ServicesLiContentCol>
                                        </ServicesRightLi>
                                            
                                        })
                                    }
                                    </ServicesRightUl>
                                </Fade>
                                <ButtonWrapper>
                                    <ServicesRightBtn href="#">{JSONData.app2Json.Services.ServicesRightBtn}
                                        <RightArrow src={JSONData.app2Json.Services.RightArrow} alt=""/>
                                        <RightArrowYellow src={JSONData.app2Json.Services.RightArrowYellow} alt=""/>
                                    </ServicesRightBtn>
                                </ButtonWrapper>
                            </ServicesRight>
                        </Col>
                    </Row>
                </ServicesInner>
            </Container>
        </ServicesWrapper>
    );
}

export default Services;