import React from 'react';
import {BannerWrapper,
    BannerContents,
    BannerContentLeft,
    BannerContentRight,
    BannerBtn,RightArrowYellow,
    BannerPara,RightArrow,
    BannerH3,BannerH4,
    BannerImage
} from './banner.style'
import {Container} from '@bootstrap-styled/v4';

import { useStaticQuery, graphql } from 'gatsby';
import Fade from "react-reveal/Fade";

const Banner = () => {
    const JSONData  = useStaticQuery(graphql`
        query{
            app2Json {
                Banner {
                    DelayConstant
                    BannerH3
                    BannerH4
                    BannerPara
                    BannerBtn
                    BannerImage
                    RightArrow
                    RightArrowYellow
                }
            }
        }
    `);
        return (
        <BannerWrapper id="homeContainer">
            <Container>
                <BannerContents>

                    <BannerContentRight>
                        <Fade bottom delay={1*JSONData.app2Json.Banner.DelayConstant}>
                            <BannerImage src={JSONData.app2Json.Banner.BannerImage} alt=""/>
                        </Fade>
                    </BannerContentRight>
                    <BannerContentLeft>
                        <Fade bottom delay={1*JSONData.app2Json.Banner.DelayConstant}>
                            <BannerH3>{JSONData.app2Json.Banner.BannerH3}</BannerH3>
                            <BannerH4>{JSONData.app2Json.Banner.BannerH4}</BannerH4>
                            <BannerPara>{JSONData.app2Json.Banner.BannerPara}</BannerPara>
                            <BannerBtn href="#">{JSONData.app2Json.Banner.BannerBtn} 
                                <RightArrow src={JSONData.app2Json.Banner.RightArrow} alt=""/>
                                <RightArrowYellow src={JSONData.app2Json.Banner.RightArrowYellow} alt=""/>
                            </BannerBtn>
                        </Fade>
                    </BannerContentLeft>
                </BannerContents>
            </Container>
        </BannerWrapper>
    );
}

export default Banner;