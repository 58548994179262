import React from 'react';
import {Container,Row,Col} from '@bootstrap-styled/v4';
import {DownloadSection,DownloadInner,DownloadCol1,DownloadTitle,DownloadSubTitle,
    DownloadPara,DownloadLinkWrapper,DownloadLeftCol,DownloadContentBg,
    DownloadCol2
} from './download.style';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from "react-reveal/Fade";
import GatsImg from "gatsby-image";

const Download =()=>{
    const JSONData  = useStaticQuery(graphql`
        query{
            app2Json {
                Download {
                    DelayConstant
                    DownloadTitle
                    DownloadSubTitle
                    DownloadPara
                    DownloadLinkImgApple{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    DownloadLinkImgGoogle{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    DownloadDemo{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    `);
    return (
        <DownloadSection id="downloadContainer">
            <DownloadContentBg>
                <Container>
                <Row>
                    <Col md="12">
                        <DownloadInner>
                            
                            <DownloadCol1>
                                <Fade right delay={1*JSONData.app2Json.Download.DelayConstant}>
                                    <DownloadTitle>
                                        {JSONData.app2Json.Download.DownloadTitle}
                                    </DownloadTitle>
                                </Fade>
                                <Fade right delay={2*JSONData.app2Json.Download.DelayConstant}>
                                    <DownloadSubTitle>
                                    {JSONData.app2Json.Download.DownloadSubTitle}
                                    </DownloadSubTitle>
                                </Fade>
                                <Fade right delay={3*JSONData.app2Json.Download.DelayConstant}>
                                    <DownloadPara>
                                    {JSONData.app2Json.Download.DownloadPara}
                                    </DownloadPara>
                                </Fade>
                               
                                <Fade right delay={1*JSONData.app2Json.Download.DelayConstant}>
                                    <DownloadLinkWrapper>
                                        <DownloadLeftCol>
                                            <GatsImg 
                                                fluid={JSONData.app2Json.Download.DownloadLinkImgApple.childImageSharp.fluid} 
                                                imgStyle={{
                                                    marginBottom:"0px",
                                                }}
                                                className="DownloadImage"
                                                alt=""
                                            />
                                        </DownloadLeftCol>
                                        <DownloadLeftCol>
                                            <GatsImg 
                                                fluid={JSONData.app2Json.Download.DownloadLinkImgGoogle.childImageSharp.fluid} 
                                                className="DownloadImage"
                                                imgStyle={{
                                                    marginBottom:"0px",
                                                }}
                                                alt=""
                                            />
                                        </DownloadLeftCol>
                                    </DownloadLinkWrapper>
                                </Fade>
                            </DownloadCol1>

                            <DownloadCol2>
                                <Fade left delay={1*JSONData.app2Json.Download.DelayConstant}>
                                    <GatsImg 
                                        fluid={JSONData.app2Json.Download.DownloadDemo.childImageSharp.fluid} 
                                        className="DownloadDemo"
                                        imgStyle={{
                                            margin:"0px auto",
                                            width:"auto",
                                            right:"0px"
                                        }}
                                        alt=""
                                    />
                                </Fade>
                            </DownloadCol2>
                        </DownloadInner>
                    </Col>
                </Row>
            </Container>
            </DownloadContentBg>
        </DownloadSection>
    );
}
export default Download;