import React from "react";
import Banner from "../containers/App2/Banner";
import HeaderMenu from "../containers/App2/HeaderMenu";
import Footer from "../containers/App2/Footer";
import Features from "../containers/App2/Features";
import AppFeatures from "../containers/App2/AppFeatures";
import Pricing from "../containers/App2/Pricing";
import Services from "../containers/App2/Services";
import Download from '../containers/App2/Download';
import Faq from '../containers/App2/Faq';
import Contact from "../containers/App2/Contact";
import GlobalStyle from "../containers/App2/Common/global-styles";
import "../components/layout.css";
import SEO from "../components/seo";
import Fonts from "../containers/App2/Common/fonts"
import BuyNow from "../components/BuyNow";

const App = () => (
   <div>
       <Fonts />
       <GlobalStyle />
       <BuyNow />
       <HeaderMenu/>
       <main>
            <SEO 
                title="App 2 ReactJS Landing Template | GatsbyJS Templates | Zircon"
                description="Buy App 2 Landing page template from Reactrepo. Template is built with react, gatsbyjs, bootstrap and styled components"
            />
            <Banner/>
            <Features/>
            <AppFeatures/>
            <Services/>
            <Pricing/>
            <Download/>
            <Faq/>
            <Contact/>
        </main>
       <Footer/>
   </div>

)

export default App;
